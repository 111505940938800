var REGEX_DATE    = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
var REGEX_EMAIL   = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var REGEX_PHONE   = /^\+?[\(\) 0-9\-]+$/;
var REGEX_NUMERIC = /^\d+$/;

var error_messages = {
  required: "This field must not be blank",
  required_checkbox: "This checkbox must be checked",
  email: "Please provide a valid e-mail address",
  date: "Must be in MM/DD/YYYY format",
  phone: "Please provide a valid phone number",
  numeric: "Only numbers allowed",
};

$(function () {
  var $form = $("form");

  var $opt = $("<option />")
    .attr("value", "")
    .html("--");
  $("select", $form).prepend($opt).val("");

  $form.on({
    submit: function () {
      var isValid = true;
      var $inputs = $(this).find(":input");
      $inputs.each(function () {
        if (!validateElement($(this))) {
          isValid = false;
        }
      });

      return isValid;
    }
  });

  $("input, select, textarea", $form).on({
    blur: function () {
      validateElement($(this));
    }
  });

  var uniqueBindToTargets = [];
  $("[data-bind-to]").each(function () {
    var target = $(this).data("bind-to");

    if (uniqueBindToTargets.indexOf(target) !== -1) return;
    uniqueBindToTargets.push(target);

    var element = $(":input[name=" + target + "]:not(:hidden)");

    switch (element.prop("type")) {
      case "checkbox":
        element.on({
          change: function () {
            var isChecked = $(this).is(":checked");

            var $boundElement = $("[data-bind-to=" + element.attr("name") + "]");
            if (isChecked) {
              $boundElement.show();
            } else {
              $boundElement.hide();
            }
          }
        });

        break;

      case "radio":
        element.on({
          click: function () {
            var value = $(this).val();

            var $boundElement = $("[data-bind-to=" + element.attr("name") + "]");
            $boundElement.hide();

            $("[data-bind-to=" + element.attr("name") + "][data-target=" + value + "]").show();
          }
        });

        break;

      case "select-one":
        element.on({
          change: function () {
            var value = $(this).val();

            var $boundElement = $("[data-bind-to=" + element.attr("name") + "]");
            $boundElement.hide();

            $("[data-bind-to=" + element.attr("name") + "][data-target=" + value + "]").show();
          }
        });

        break;
    }

    element.trigger("change");
  });

  function validateElement(element) {
    if (!element.attr("id")) return true;

    var $parent = element.parents("div:first");
    $parent.removeClass("has-errors");
    $(".errors", $parent).remove();
    var errors = [];
    var value  = element.val();

    if (!element.is(":visible")) return true;

    var isRequired = $parent.hasClass("required") || $("label.required", $parent).length > 0;

    if (!value && !isRequired) return true;

    var isDate    = $parent.hasClass("date");
    var isEmail   = $parent.hasClass("email");
    var isPhone   = $parent.hasClass("phone");
    var isNumeric = $parent.hasClass("numeric");

    var isInput    = $parent.hasClass("input");
    var isCheckbox = $parent.hasClass("checkbox");
    var isRadio    = $parent.hasClass("radio");
    var isSelect   = $parent.hasClass("select");

    if (isRequired) {
      if ((isInput || isSelect) && !value) {
        errors.push(error_messages.required);
      } else if (isCheckbox && !element.is(":checked")) {
        errors.push(error_messages.required_checkbox);
      } else if (isRadio) {
        if (!$("input[name=" + element.attr("name") + "]:checked").length) {
          errors.push(error_messages.required);
        }
      }
    }

    if (isDate && !REGEX_DATE.test(value)) {
      errors.push(error_messages.date);
    }

    if (isEmail && !REGEX_EMAIL.test(value)) {
      errors.push(error_messages.email);
    }

    if (isPhone && !REGEX_PHONE.test(value)) {
      errors.push(error_messages.phone);
    }

    if (isNumeric && !REGEX_NUMERIC.test(value)) {
      errors.push(error_messages.numeric);
    }

    if (errors.length) {
      $parent.addClass("has-errors");
      $parent.append(
        $("<div />", {
          class: "errors",
          html: errors.map(function (message) {
            return $("<div />", {text: message});
          })
        })
      );
    }

    return errors.length === 0;
  }
});
