window.sr = ScrollReveal({ duration: 900, distance: '10px', origin: 'bottom' });

sr.reveal('.sr-default', { origin: 'bottom'});

// home
sr.reveal('.sr-title', { origin: 'bottom', delay: 0 });
sr.reveal('.sr-para', { origin: 'bottom', delay: 400 });
sr.reveal('.sr-image', { origin: 'bottom', delay: 600 });
sr.reveal('.h_care-service-item-icon img', { origin: 'bottom'});

//services
sr.reveal('.s_services_detailed-list-item-image', { origin: 'top'});

//company
sr.reveal('.comp_team-list-member', { duration: 2000 }, 90);

//404
sr.reveal('.fourohfourinner-img', { origin: 'left'});
sr.reveal('.fourohfourinner-content', { origin: 'right'});



