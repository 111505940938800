var REGEX_DATE    = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
var REGEX_EMAIL   = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var REGEX_PHONE   = /^\+?[\(\) 0-9\-]+$/;
var REGEX_NUMERIC = /^\d+$/;

var errorMessages = {
  minLength: "The minimum length is {length}",
  maxLength: "The maximum length is {length}",
  numeric: "Must contain only numeric characters",
  email: "This is not a valid email address",
  phone: "This is not a valid phone number",
  date: "Invalid date",
  required: "This field is required",
};

$(function () {
  var $form = $("form");

  $form.on({
    submit: function (event) {
      var self = $(this);
      var inputsValid = true;

      $("*[data-validate]").each(function () {
        if ($(this).data("valid") === undefined) {
          validate($(this), true);
          if (!$(this).data("valid")) {
            inputsValid = false;
          }
        } else if (!$(this).data("valid")) {
          inputsValid = false;
        }
      });

      if (!inputsValid) {
        event.preventDefault();
        event.stopPropagation();

        return false;
      }

      if (self.hasClass("ajax-login-form")) {
        var $submit = $("button[type=submit]", self);
        $submit.next("ul.errors").remove();
        $submit.prop("disabled", true);

        $.ajax({
          url: self.attr("action"),
          type: "post",
          data: self.serialize(),
          success: function () {
            $.ajax({
              url: $("input[name=login-check]", self).val(),
              type: "get",
              dataType: "json",
              success: function (response) {
                if (response.success) {
                  window.location.href = $("input[name=RET]", self).val();
                } else {
                  $submit.prop("disabled", false);
                  $submit.after("<ul class='errors'><li>Incorrect login information</li></ul>");
                }
              }
            });
          }
        });

        return false;
      }

      $("*[disabled]").prop("disabled", false);
    }
  });

  $("*[data-validate]").on({
    blur: function (event) {
      validate($(this), true);
    },
    keyup: function (event) {
      validate($(this));
    }
  });

  function validate(element, includeAjax) {
    var isRequired        = element.data("required");
    var minLength         = element.data("min-length");
    var maxLength         = element.data("max-length");
    var numericOnly       = element.data("numeric");
    var email             = element.data("email");
    var phone             = element.data("phone");
    var date              = element.data("date");
    var ajaxValidationUrl = element.data("validation-url");

    var isCheckbox = element.is(":checkbox");

    var value  = element.val() ? element.val() : "";
    var errors = [];

    if (isRequired !== undefined) {
      if (isCheckbox && !element.is(":checked")) {
        errors.push(errorMessages.required);
      } else if (!value.length) {
        errors.push(errorMessages.required);
      }
    }

    if (!value.length && isRequired === undefined) {
      element.data("errors", errors);
      element.data("valid", !errors.length);
      showErrors(element);

      return;
    }

    if (minLength !== undefined && value.length < parseInt(minLength)) {
      errors.push(errorMessages.minLength.replace("{length}", minLength));
    }

    if (maxLength !== undefined && value.length > parseInt(maxLength)) {
      errors.push(errorMessages.maxLength.replace("{length}", maxLength));
    }

    if (numericOnly !== undefined && !REGEX_NUMERIC.test(value)) {
      errors.push(errorMessages.numeric);
    }

    if (email !== undefined && !REGEX_EMAIL.test(value)) {
      errors.push(errorMessages.email);
    }

    if (phone !== undefined && !REGEX_PHONE.test(value)) {
      errors.push(errorMessages.phone);
    }

    if (date !== undefined && !REGEX_DATE.test(value)) {
      errors.push(errorMessages.date);
    }

    if (includeAjax && ajaxValidationUrl !== undefined) {
      var data = {};
      var name = element.attr("name");

      data["value"] = element.val();

      var passRepeat = $("*[data-password-repeat]:first");
      var pass       = $("*[data-password]:first");
      var memberId   = element.data("member-id");

      if (element.data("password") !== undefined) {
        data["confirm"] = passRepeat.val();
      }

      if (element.data("password-repeat") !== undefined) {
        data["confirm"] = pass.val();
      }

      if (memberId !== undefined) {
        data["member_id"] = memberId;
      }

      var response = $.ajax({
        url: ajaxValidationUrl,
        type: "post",
        dataType: "json",
        data: data,
        async: false,
      }).responseJSON;

      if (!response.valid) {
        errors = errors.concat(response.errors);
      }

      if (element.data("password-repeat") !== undefined && !errors.length) {
        pass.data("errors", errors);
        pass.data("valid", !errors.length);
        showErrors(pass);
      }
    }

    element.data("errors", errors);
    element.data("valid", !errors.length);
    showErrors(element);

    return !errors.length;
  }

  function showErrors(element) {
    element.next("ul.errors").remove();
    var errors = element.data("errors");
    if (errors.length) {
      var $ul = $("<ul>", {class: "errors"});

      var index = errors.length - 1;
      if (element.data("first-error") !== undefined) {
        index = 0;
      }

      var message = errors[index];
      $ul.append($("<li>", {html: message}));

      // for (var i = 0; i < errors.length; i++) {
      //   var message = errors[i];
      //   $ul.append($("<li>", {html: message}));
      // }

      element.after($ul);
    }
  }

  var $billingWrapper = $("#billing-info-wrapper");
  var $shippingWrapper = $("#shipping-info-wrapper");
  var $useBillingInfo = $("#use-billing-info");
  $useBillingInfo.on({
    click: handleUseBillingInfo
  });

  function handleUseBillingInfo(event) {
    var isChecked = $useBillingInfo.is(":checked");

    var $billingItems = $("input, select, textarea", $billingWrapper);
    var $shippingItems = $("input, select, textarea", $shippingWrapper);

    $shippingItems.each(function(){
      if ($(this).attr("id") !== "use-billing-info") {
        if ($(this).data("placeholder") === undefined) {
          var placeholder = $(this).attr("placeholder");
          $(this).data("placeholder", placeholder);
        }

        $(this)
          .prop("disabled", isChecked)
          .attr("placeholder", isChecked ? "" : $(this).data("placeholder"));
      }
    });

    if (isChecked) {
      $billingItems.each(function() {
        var counterpart = $("*[name=shipping_" + $(this).attr("name") + "]");
        counterpart.val($(this).val());
        $(this).bind("change", handleInfoCopy);
      });
    } else {
      $billingItems.each(function() {
        $(this).unbind("change", handleInfoCopy);
      })
    }
  }

  function handleInfoCopy(event) {
    var self = $(event.target);
    var counterpart = $("*[name=shipping_" + self.attr("name") + "]");
    counterpart.val(self.val());
    if (self.data("validate") !== undefined) {
      validate(counterpart, false);
    }
  }
});
